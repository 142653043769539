import React from "react";
import { Field, useFormikContext } from "formik";

import AACheckbox from "../ActiveAdmin/AACheckbox";
import AASelectInput from "../ActiveAdmin/AASelectInput";
import { typeOptions } from "../selectOptions";

const TypeSubform = () => {
  const {
    values: { type, foreignDocument, fakeImage },
  } = useFormikContext();

  const showAttributes = type === 'receipt' || type === 'prescription';

  return (
    <fieldset className="inputs">
      <ol>
        <Field type="hidden" name="id" />
        <AASelectInput
          label="Тип документа"
          name="type"
          hint="Проверьте тип документа"
          withEmptyOption={false}
          options={typeOptions}
        />
        {
          showAttributes ? (
          <React.Fragment>
            <AACheckbox
              name="foreignDocument"
              label="Куплено/выписано за пределами России"
            />
            {foreignDocument ? null : (
              <React.Fragment>
                <AACheckbox name="fakeImage" label="Подделка (фотошоп)" />
                {fakeImage ? null : (
                  <AACheckbox name="sharpImage" label="Фото разборчивое" />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
          ) : null
        }
      </ol>
    </fieldset>
  );
};

export default TypeSubform;
