import React from "react";

import { Formik, Form } from "formik";
import humps from "humps";

import TypeSubform from "./AttachmentForm/TypeSubform";
import Subforms from "./AttachmentForm/Subforms";
import AutoSave from "./AttachmentForm/AutoSave";

import { buildAttachmentData, handleFormError } from 'functions/Data';

import { updateAttachment, completeAttachment } from "./api";

const AttachmentForm = ({ model }) => {
  function redirectToTicket(attachmentId) {
    let url = "" + window.location;
    window.location.replace(
      url.replace(
        `/attachments/${attachmentId}/edit`,
        `#attachment_${attachmentId}`
      )
    );
  }

  const onComplete = (values, { setFieldError, setSubmitting }) => {
    setSubmitting(true);
    completeAttachment(buildAttachmentData(values))
      .then(
        () => redirectToTicket(values.id),
        (error) => handleFormError(error, setFieldError)
      )
      .finally(() => setSubmitting(false));
  };

  // выключаем автоотправку формы, если не выбрано ЛС/ДВ
  const formIsUnready = (values) =>
    values.purchases.map((p) => p.medicineId).includes("") ||
    values.prescriptionMedicines.map((p) => p.medicineId).includes("");

  const onSubmit = (values, { setFieldError, setSubmitting }) => {
    setSubmitting(true);
    updateAttachment(buildAttachmentData(values))
      .then(
        () => {},
        (error) => handleFormError(error, setFieldError)
      )
      .finally(() => setSubmitting(false));
  };
  return (
    <Formik enableReinitialize={true}
            initialValues={humps.camelizeKeys(model)}
            onSubmit={onSubmit}>
      {({ isSubmitting, errors, setFieldError, setSubmitting, values }) => (
        <React.Fragment>
          <Form>
            <AutoSave debounceMs={1000} />
            {Object.keys(errors).length > 0 && !isSubmitting ? (
              <div className="flashes">
                <div className="flash flash_error">Ошибка валидации</div>
              </div>
            ) : null}
            <TypeSubform />
            <Subforms />
          </Form>
          <div>
            <button
              className={
                isSubmitting || formIsUnready(values)
                  ? "button disabled"
                  : "button"
              }
              disabled={isSubmitting || formIsUnready(values)}
              onClick={(e) => {
                e.preventDefault();
                onComplete(values, { setFieldError, setSubmitting });
              }}
            >
              Проверить и завершить
            </button>
          </div>
        </React.Fragment>
      )}
    </Formik>
  );
};

export default AttachmentForm;
