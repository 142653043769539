import axios from "axios";
import humps from "humps";

export const api = axios.create({
  baseURL: "",
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => humps.camelizeKeys(data),
  ],
  transformRequest: [
    function(data, headers) {
      var token = document.querySelector('[name=csrf-token]').content;
      headers.common['X-CSRF-TOKEN'] = token;
      return data;
    },
    (data) => humps.decamelizeKeys(data),
    ...axios.defaults.transformRequest,
  ],
});

export const completeAttachment = (values) =>
  api({
    method: "put",
    responseType: "json",
    url: `/api/attachments/${values.id}/complete`,
    data: values,
  });

export const updateAttachment = (values) =>
  api({
    method: "put",
    responseType: "json",
    url: `/api/attachments/${values.id}`,
    data: values,
  });

export const identifySales = (values) =>
  api({
    method: "post",
    url: `/api/sales/identify`,
    data: values,
  });

export const fetchCities = () =>
  api({
    method: "get",
    responseType: "json",
    url: "/api/cities"
  });

export const fetchDiseaseClasses = (filter) =>
  api({
    method: "get",
    responseType: "json",
    url: "/api/widget/ticket/mkb_disease_classes",
    params: { filter }
  });

export const createDiseaseClass = (data) =>
  api({
    method: "post",
    url: "/api/ticket_rls_disease_classes",
    data
  });

export const updateDiseaseClass = (id, data) =>
  api({
    method: "patch",
    responseType: "json",
    url: `/api/ticket_rls_disease_classes/${id}`,
    data
  });

export const verifyByRls = (id) =>
  api({
    method: "post",
    responseType: "json",
    url: `/api/tickets/${id}/verify_by_rls_api`
  });
