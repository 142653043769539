import React from "react";
import { useField } from "formik";

const AACheckbox = ({ children, label, hint, value, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <li className="custom_boolean">
      <label>
        <input type="checkbox" {...field} {...props}/>
        {label ? label : null}
        {children}
      </label>
      {meta.error ? (
        <p className="custom_error">{meta.error}</p>
      ) : null}
      {hint ? <p className="custom_hint">{hint}</p> : null}
    </li>
  );
};

export default AACheckbox;
