import React from "react";
import { useFormikContext } from "formik";

import ReceiptSubform from "./ReceiptSubform";
import PrescriptionSubform from "./PrescriptionSubform";

const Subforms = () => {
  const {
    values: { type, foreignDocument, fakeImage, sharpImage },
  } = useFormikContext();

  const showSubforms = !foreignDocument && !fakeImage && sharpImage;
  const isReceipt = type === "receipt";
  const isPrescription = type === "prescription";

  return showSubforms ? (
    <React.Fragment>
      {isReceipt ? <ReceiptSubform /> : null}
      {isPrescription ? <PrescriptionSubform /> : null}
    </React.Fragment>
  ) : null;
};

export default Subforms;
