import React from "react";
import { Field, useFormikContext } from "formik";

import AAHasMany from "../ActiveAdmin/AAHasMany";
import AATextInput from "../ActiveAdmin/AATextInput";
import AASelectInput from "../ActiveAdmin/AASelectInput";
import {
  excludedFromProgramOptions,
  purchaseReleaseFormOptions
} from "../selectOptions";

const PurchasesSubform = () => {
  const {
    values: { purchases },
  } = useFormikContext();

  const mapTotalAmounts = (values) => {
    return values.map((v) => +v.quantity * +v.amount);
  };

  const newItem = {
    name: "",
    quantity: 0,
    quantityByPrescription: 0,
    dosage: 0,
    amountInPackage: 0,
    amount: 0,
    releaseForm: '',
    includedInPrescription: "",
    excludedFromProgram: "",
    excludedFromProgramReasons: [],
  };

  return (
    <AAHasMany
      name="purchases"
      label="Покупки"
      items={purchases}
      newItem={newItem}
      render={(purchase, index) => (
        <React.Fragment>
          <Field type="hidden" name="`purchases.${index}.id`" />
          <AATextInput
            label={`${index + 1}. Позиция`}
            name={`purchases.${index}.name`}
          />
          <AATextInput
            label="Кол-во единиц"
            name={`purchases.${index}.quantity`}
            type="number"
            step="1"
            min="0"
          />
          <AATextInput
            label="Кол-во единиц по рецепту"
            name={`purchases.${index}.quantityByPrescription`}
            type="number"
            step="1"
            min="0"
          />
          <AATextInput
            label="Дозировка"
            name={`purchases.${index}.dosage`}
            type="number"
            step="1"
            min="0"
          />
          <AATextInput
            label="Кол-во в упаковке"
            name={`purchases.${index}.amountInPackage`}
            type="number"
            step="1"
            min="0"
          />
          <AASelectInput
            label="Форма выпуска"
            name={`purchases.${index}.releaseForm`}
            options={purchaseReleaseFormOptions}
          />
          <AATextInput
            label="Стоимость одной единицы"
            name={`purchases.${index}.amount`}
            type="number"
            step="0.01"
            min="0"
          />
          <AATextInput
            label="Общая стоимость"
            name={`purchases.${index}.totalAmount`}
            value={mapTotalAmounts(purchases)[index]}
            disabled={true}
            type="number"
          />
          <AASelectInput
            label="Куплено по назначению"
            name={`purchases.${index}.includedInPrescription`}
            boolean={true}
          />
          <AASelectInput
            label="Исключено из программы"
            name={`purchases.${index}.excludedFromProgram`}
            boolean={true}
          />
          {purchase.excludedFromProgram ? (
            <AASelectInput
              label="Причины исключения"
              isMulti
              name={`purchases.${index}.excludedFromProgramReasons`}
              options={excludedFromProgramOptions}
              withEmptyOption={false}
            />
          ) : null}
        </React.Fragment>
      )}
    />
  );
};

export default PurchasesSubform;
