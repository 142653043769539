import React from "react";
import { FieldArray, useField } from "formik";

const AAHasMany = ({ name, label, items = [], render, newItem = {}, ...props }) => {
  const [,meta,] = useField(props);
  const errors = (meta.error[name] || []).filter((e) => typeof e === 'string');

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <React.Fragment>
          <h3>{label}</h3>
          {errors.map((e) => (
            <p className="common-error">{e}</p>
          ))}
          <fieldset className="inputs">
            <ol>
              <li className="has_many_container">
                {items.length > 0 &&
                  items.map((child, index) => (
                    <fieldset key={index} className="inputs has_many_fields">
                      <ol>
                        {render(child, index)}
                        <li
                          style={{ padding: "10px 12px", textAlign: "right" }}
                        >
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Удалить
                          </button>
                        </li>
                      </ol>
                    </fieldset>
                  ))}
                <button
                  type="button"
                  onClick={() => arrayHelpers.push(newItem)}
                >
                  Добавить
                </button>
              </li>
            </ol>
          </fieldset>
        </React.Fragment>
      )}
    />
  );
};

export default AAHasMany;
