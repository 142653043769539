import React from "react";
import { useField } from "formik";

const AATextInput = ({ label, hint, type = "text", wrapperClassName = '', ...props }) => {
  const [field, meta] = useField(props);

  return (
    <li className={wrapperClassName}>
      {label && <label htmlFor={props.id || props.name} className="custom_label">{label}</label>}
      <input className="text-input" type={type} {...field} {...props} />
      {meta.error ? <p className="custom_error">{meta.error}</p> : null}
      {hint ? <p className="custom_hint">{hint}</p> : null}
    </li>
  );
};

export default AATextInput;
