import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import "react-datepicker/dist/react-datepicker.css";

import AASelectInput from "../ActiveAdmin/AASelectInput";
import AATextInput from "../ActiveAdmin/AATextInput";
import PurchasesSubform from "./PurchasesSubform";
import AADatepicker from "../ActiveAdmin/AADatepicker";

import { fetchCities } from "../api";

const ReceiptSubform = () => {
  const {
    values: { purchases },
  } = useFormikContext();

  const [totalPurchasesAmount, setTotalPurchasesAmount] = useState(0);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    fetchCities().then(({ data }) => setCities(data.cities),
      error => alert(error)
    );
  }, []);

  useEffect(() => {
    setTotalPurchasesAmount(
      purchases.reduce((total, e) => total + +e.amount * +e.quantity, 0)
    );
  }, [purchases, setTotalPurchasesAmount]);

  return (
    <React.Fragment>
      <fieldset className="inputs">
        <ol>
          <AATextInput label="ИНН аптеки" name="pharmacyInn" />
          <AATextInput label="Название аптеки" name="pharmacyName" />

          <AASelectInput
            label="Регион"
            name="pharmacyRegion"
            withEmptyOption={false}
            options={cities}
            isFieldValueObject
            getOptionLabel={({ name: cityName }) => cityName}
            getOptionValue={({ region_id: regionId, id }) => regionId || id}
          />

          <AADatepicker
            label="Дата и время покупки"
            name="receiptTime"
            dateFormat="dd.MM.yyyy HH:mm"
            showTimeInput
          />
          <AATextInput label="(ФН) Заводской номер фискального накопителя" name="storageNumber" />
          <AATextInput label="(ФД) Порядковый номер фискального документа" name="fiscalDocumentId" />
          <AATextInput label="(ФП или ФПД) Фискальный признак документа" name="fiscalSign" />
          <AATextInput
            label="Сумма чека"
            name="totalAmount"
            type="number"
            step="0.01"
            min="0"
            hint={`Сумма по позициям в чеке: ${totalPurchasesAmount}`}
          />
        </ol>
      </fieldset>
      <PurchasesSubform />
    </React.Fragment>
  );
};

export default ReceiptSubform;
