export const diagnosisStatusOptions = [
  { value: "unconfirmed", label: "Не подтверждён" },
  { value: "confirmed", label: "Подтверждён" },
  { value: "ignored", label: "Нет, игнорируем" }
];

export const typeOptions = [
  { value: "prescription", label: "Рецепт" },
  { value: "receipt", label: "Чек" },
  { value: "additional_prescription", label: "Дополнительный рецепт" },
  { value: "receipt_part", label: "Часть чека" },
  { value: "duplicate", label: "Дубликат" },
  { value: "prescription_part", label: "Часть рецепта" },
  { value: "other", label: "Иное" },
];

export const prescriptionTypeOptions = [
  { value: "appointment", label: "Назначение" },
  { value: "appointment_telemed", label: "Назначение (телемедицина)" },
  { value: "prescription", label: "Рецепт" },
  { value: "extract", label: "Выписка из больницы" },
];

export const prescriptionBlankTypeOptions = [
  "Свободная форма",
  "Форма № 107/у-НП",
  "Форма № 148-1/у-88 «Рецептурный бланк»",
  "Форма № 107-1/у «Рецептурный бланк»",
  "Форма № 148-1/04 (л)",
].map((value) => ({ value: value, label: value }));

export const excludedFromProgramOptions = [
  "Не лекарство (гигиена, косметика, мед оборудование, очки, линзы, импланты, уход и пр.)",
  "БАДы (п.4.2.8)",
  "Лечение последствий: прием алкоголя, наркотики, болезни по ВИЧ или вирусный гепатит (п.4.1.4)",
  "Лекарства не прошли гос регистрацию и/или отсутствуют в реестре (п.4.2.6)",
  "Не готовая лекарственная форма (п.4.2.7)",
].map((value) => ({ value: value, label: value }));

export const purchaseReleaseFormOptions = [
  "Капсулы",
  "Таблетки",
  "Свечи",
  "Гель",
  "Мазь",
  "Сироп",
  "Экстракт",
  "Эликсир",
  "Эмульсия",
  "Карандаш",
  "Отвар",
  "Микстура",
  "Раствор",
  "Порошок",
].map((value) => ({ value: value, label: value }));
