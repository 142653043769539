import React, { useEffect, useRef, useState } from 'react';

import Select from 'react-select';

import { createDiseaseClass, fetchDiseaseClasses, updateDiseaseClass } from 'api';

import { diagnosisStatusOptions } from 'selectOptions';

// Форма для редактирования диагноза
const DiagnosisForm = ({ ticketId, diagnosisItem, setTicketDiagnoses }) => {
  const {
    id: initialDiagnosisId,
    rls_disease_class: initialDiagnosis,
    status: initialStatus
  } = diagnosisItem;

  const [diagnosesList, setDiagnosesList] = useState([]);
  const [filterByNameOrCode, setFilterByNameOrCode] = useState('');

  const [diagnosis, setDiagnosis] = useState(initialDiagnosis);
  const [status, setStatus] = useState(diagnosisStatusOptions.find(({ value }) => initialStatus === value) || {});

  const timer = useRef(null);

  // Фильтр диагнозов по названию или коду МКБ
  useEffect(() => {
    if (filterByNameOrCode) {
      clearTimeout(timer.current);

      timer.current = setTimeout(() => fetchDiseaseClasses(filterByNameOrCode).then(
        ({ data }) => setDiagnosesList(data),
        error => alert(error)
      ), 1000);
    } else setDiagnosesList([]);

    return () => clearTimeout(timer.current);
  }, [filterByNameOrCode]);

  // Создание или редактирование дигноза, при изменении/заполнении полей
  useEffect(() => {
    const isFieldsFilled = status.value && diagnosis?.id;
    const isDiagnosisChanged = (diagnosis !== initialDiagnosis) || (status.value !== initialStatus);

    if (isFieldsFilled && isDiagnosisChanged) {
      if (initialDiagnosisId) updateDiagnosis();
      else createDiagnosis();
    }
  }, [diagnosis, status]);

  // Формирование данных для запроса
  const buildFormData = () => {
    return {
      ticket_rls_disease_class: {
        ticket_id: ticketId,
        rls_disease_class_id: diagnosis.id,
        status: status.value
      }
    };
  }

  // Обновление диагноза
  const updateDiagnosis = () => {
    updateDiseaseClass(initialDiagnosisId, buildFormData()).then(
      ({ data }) => setTicketDiagnoses(prev => {
        const diagnosisItemIndex = prev.findIndex(({ id }) => initialDiagnosisId === id);

        const newTicketDiagnosis = prev.toSpliced(diagnosisItemIndex, 1, data);

        return newTicketDiagnosis;
      }),
      error => alert(error)
    );
  };

  // Создание диагноза
  const createDiagnosis = () => {
    createDiseaseClass(buildFormData()).then(
      ({ data }) => setTicketDiagnoses(prev => {
        const newState = prev;

        newState[newState.length - 1] = data;

        return newState;
      }),
      error => alert(error)
    );
  };

  // Вывод селекта диагноза
  const renderDiagnosisSelect = () => (
    <li>
      <label htmlFor='diagnosis' className='custom_label'>Диагноз</label>

      <Select name='diagnosis'
              value={diagnosis}
              options={diagnosesList}
              classNamePrefix='diagnosis'
              placeholder='Введите название или код диагноза...'
              onInputChange={setFilterByNameOrCode}
              getOptionLabel={option => option?.name}
              getOptionValue={option => option?.id}
              filterOption={() => true}
              onChange={setDiagnosis} />
    </li>
  );

  // Вывод кода МКБ
  const renderCodeInput = () => (
    <li>
      <label htmlFor='code' className='custom_label'>Код МКБ-10</label>
      <input className='text_input'
             name='code'
             type='text'
             value={diagnosis?.code}
             disabled />
    </li>
  );

  // Вывод селекта статуса диагноза
  const renderStatusSelect = () => (
    <li>
      <label htmlFor='status' className='custom_label'>Статус</label>

      <Select name='status'
              value={status}
              options={diagnosisStatusOptions}
              classNamePrefix='status'
              placeholder='Введите статус диагноза...'
              filterOption={() => true}
              onChange={setStatus} />
    </li>
  );

  const renderContent = () => (
    <form className='diagnosis_and_code'>
      <fieldset className='inputs'>
        <ol className=''>
          {renderDiagnosisSelect()}

          {renderCodeInput()}
          
          {renderStatusSelect()}
        </ol>
      </fieldset>
    </form>
  );

  return renderContent();
};

export default DiagnosisForm;
