import React, { useCallback, useState } from 'react';

import AppointmentShortForm from 'AttachmentForm/AppointmentShortForm';
import ReceiptsShortForm from 'AttachmentForm/ReceiptsShortForm';

import DiagnosisForm from 'DiagnosisForm';
import ImageViewer from 'ImageViewer';
import TicketTotalLosses from 'TicketTotalLosses';

import { buildAttachmentData, handleFormError } from 'functions/Data';

import { completeAttachment, verifyByRls } from 'api';

import { navigationUrls } from 'config/routes';

// Вывод блока с приложенными файлами
const AttachmentsProcessing = ({ ticketId, prescriptions, diseaseClasses, receipts, ticketCashback }) => {
  const [ticketDiagnoses, setTicketDiagnoses] = useState(diseaseClasses?.length ? diseaseClasses : [{}]);

  const [ticketReceipts, setTicketReceipts] = useState(receipts);
  const [ticketTotalCashback, setTicketTotalCashback] = useState(ticketCashback);

  const [isViewerContainerExist, setIsViewerContainerExist] = useState(false);

  const images = prescriptions.map(({ id, file_url: fileUrl }) => ({ src: fileUrl, alt: `Назначение №${id}`}));

  const measuredRef = useCallback(node => { if (node) setIsViewerContainerExist(true); }, []);

  // Отправка запроса при изменении формы
  const checkByRls = () => {
    verifyByRls(ticketId)
      .then(
        (response) => setTicketReceipts(response.data.receipts),
        (error) => alert(error)
      );
  };

  // Отправка запроса при изменении формы
  const saveAttachment = (values, { setFieldError, setSubmitting }) => {
    setSubmitting(true);

    completeAttachment(buildAttachmentData(values))
      .then(
        ({ data }) => { setTicketTotalCashback(data.ticket_cashback) },
        (error) => handleFormError(error, setFieldError)
      )
      .finally(() => setSubmitting(false));
  };

  // Вывод назначений
  const renderAppointments = () => (
    <div className='appointments_processing'>
      <h3>Блок назначений</h3>

      <div ref={measuredRef} id='image_viewer'>
        {isViewerContainerExist && <ImageViewer imagesObjects={images} domId='image_viewer' />}
      </div>

      {prescriptions?.map(item => <AppointmentShortForm key={`appointment_${item.id}`}
                                                        appointmentItem={item}
                                                        saveAttachment={saveAttachment} />)}

      <div className='ticket_diagnosis'>
        {ticketDiagnoses?.map(item => (
          <DiagnosisForm key={`diagnosis_${item.id}`}
                         ticketId={ticketId}
                         diagnosisItem={item}
                         setTicketDiagnoses={setTicketDiagnoses} />
        ))}
      </div>

      <button type='button' onClick={() => setTicketDiagnoses(prev => [...prev, {}])}>+ добавить диангноз в рамках другого документа</button>
    </div>
  );

  // Вывод чеков
  const renderReceipts = () => (
    <div className='receipts'>
      <h3>Блок чеков</h3>

      {ticketReceipts?.map(item => <ReceiptsShortForm key={`receipt_${item.id}`}
                                                      receiptItem={item}
                                                      saveAttachment={saveAttachment}
                                                      ticketId={ticketId} />)}

      {ticketTotalCashback && <TicketTotalLosses ticketCashback={ticketTotalCashback} />}

      <div className='rls_button_container'>
        {receipts.every(({ purchases }) => !!purchases.length) && <button type='button' onClick={checkByRls}>Проверить</button>}
      </div>
    </div>
  );

  const renderContent = () => (
    <div className='attachments_new_form'>
      <a className='old_version' href={navigationUrls.tickets.attachments(ticketId)}>Обработать в старой версии</a>

      <div className='attachments'>
        {renderAppointments()}

        {renderReceipts()}
      </div>
    </div>
  );

  return renderContent();
};

export default AttachmentsProcessing;
