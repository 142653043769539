import React, { useState } from "react";
import { api } from "../api";
import Autosuggest from "react-autosuggest";
import { Field, useField } from "formik";
/*
 * Input with autocompletion:
 * - can create new medicine (`createIfNotExists` param)
 * - can search across active ingredients (`withActiveIngredients` param)
 *
 * Expects `name` to be path to the medicine object, for example:
 * `prescription_medicines.${index}`
 */
const MedicinesInput = ({
  withActiveIngredients = false,
  createIfNotExists = false,
  label,
  name,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const [idField, _idMeta, idHelpers] = useField(`${name}.medicineId`);
  const [_nameField, nameMeta, nameHelpers] = useField(`${name}.medicineName`);
  const [typeField, _typeMeta, typeHelpers] = useField(`${name}.medicineType`);

  const onSuggestionInputChange = (_, { newValue }) => {
    idHelpers.setValue("");
    typeHelpers.setValue("");
    nameHelpers.setValue(newValue);
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    await api({
      method: "get",
      responseType: "json",
      url: "/api/medicines",
      params: {
        q: value,
        with_active_ingredients: withActiveIngredients,
      },
    }).then(
      (response) => {
        const results = response.data.results;
        if (createIfNotExists && !results.find((e) => e.name === value)) {
          const newSuggestion = { name: value, id: "", type: "", isNew: true };
          results.push(newSuggestion);
        }
        setSuggestions(results);
      },
      (error) => {
        alert(error);
      }
    );
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const renderSuggestion = (suggestion) => {
    const label = suggestion.isNew
      ? `Создать: ${suggestion.name}`
      : suggestion.name;
    return <span>{label}</span>;
  };

  const createAndSelectNewSuggestion = (suggestion) => {
    api({
      method: "post",
      responseType: "json",
      url: "/api/medicines",
      data: {
        name: suggestion.name,
      },
    }).then(
      (response) => {
        const suggestion = response.data;
        idHelpers.setValue(suggestion.id);
        typeHelpers.setValue(suggestion.type);
      },
      (error) => {
        alert(error);
      }
    );
  };

  const onSuggestionSelected = (_, { suggestion }) => {
    if (!suggestion.isNew) {
      idHelpers.setValue(suggestion.id);
      typeHelpers.setValue(suggestion.type);
    } else {
      createAndSelectNewSuggestion(suggestion);
    }
  };

  return (
    <React.Fragment>
      <li>
        <Field type="hidden" name={idField.name} />
        <Field type="hidden" name={typeField.name} />
        <label htmlFor="medicine_name">{label}</label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={onSuggestionSelected}
          inputProps={{
            value: nameMeta.value,
            onChange: onSuggestionInputChange,
          }}
          highlightFirstSuggestion={true}
        />
      </li>
    </React.Fragment>
  );
};

export default MedicinesInput;
