import React, { useState, useEffect, useCallback } from "react";
import { useFormikContext } from "formik";
import _ from "lodash";

const AutoSave = ({ debounceMs = 1000 }) => {
  const formik = useFormikContext();
  const [isSaved, setIsSaved] = useState(null);
  const debouncedSubmit = useCallback(
    _.debounce(() => {
      if (!formik.isSubmitting) {
        return formik.submitForm().then(() => setIsSaved(true));
      }
    }, debounceMs),
    [formik.submitForm, debounceMs, setIsSaved]
  );

  useEffect(() => {
    if (formik.isValid && formik.dirty && !formik.isSubmitting) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, formik.values]);

  return (formik.isValid && (formik.isSubmitting || isSaved)) ? (
    <div className="flashes">
      <div className="flash flash_notice">
        {formik.isSubmitting ? "Сохранение..." : "Изменения сохранены."}
      </div>
    </div>
  ) : null;
};

export default AutoSave;
