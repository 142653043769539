import React from 'react';

import { Formik, Form } from 'formik';
import humps from 'humps';

import AACheckbox from 'ActiveAdmin/AACheckbox';
import AASelectInput from 'ActiveAdmin/AASelectInput';
import AATextInput from 'ActiveAdmin/AATextInput';

import AutoSave from 'AttachmentForm/AutoSave';

import { navigationUrls } from 'config/routes';

// Вывод краткой формы обработки чеков
const ReceiptsShortForm = ({ receiptItem, saveAttachment, ticketId }) => {
  const { id, purchases, meta } = receiptItem;

  const editUrl = navigationUrls.tickets.attachmentsItem(ticketId, id)

  // Вывод полей редактирования
  const renderFields = () => (
    <fieldset className='inputs'>
      <ol className='purchase_item column_names'>
        <li><p>Н-я</p></li>
        <li><p>С-к</p></li>
        <li><p>Причин.</p></li>
        <li className='purchase_name'><p>Название препарата</p></li>
        <li className='number'><p>Кол-во</p></li>
        <li className='number'><p>Цена</p></li>
        <li className='number'><p>Сумма</p></li>
        <li className='number'><p>Кешбэк</p></li>
      </ol>
      {purchases.map((_, index) => (
        <ol className='purchase_item' key={index}>
          <AACheckbox name={`purchases.${index}.includedInPrescription`} />
          <AACheckbox name={`purchases.${index}.verifiedByRls`} disabled />
          <AASelectInput name={`purchases.${index}.excludedFromProgram`}
            boolean={true}
            wrapperClassName='exception_reason' />
          <AATextInput name={`purchases.${index}.name`} wrapperClassName='purchase_name' />
          <AATextInput name={`purchases.${index}.quantity`} wrapperClassName='number' />
          <AATextInput name={`purchases.${index}.amount`} wrapperClassName='number' />
          <AATextInput name={`purchases.${index}.totalAmount`} wrapperClassName='number' />
          <AATextInput name={`purchases.${index}.meta.cashback`} wrapperClassName='number' />
        </ol>
      ))}
      <ol className='purchase_item column_names receipt_total'>
        <li><p>Итого по чеку:</p></li>
        <AATextInput name='meta.quantity' wrapperClassName='number' />
        <AATextInput name='meta.amountPerUnit' wrapperClassName='number' />
        <AATextInput name='meta.amount' wrapperClassName='number' />
        <AATextInput name='meta.cashback' wrapperClassName='number' />
      </ol>
    </fieldset>
  );

  const renderContent = () => (
    <Formik key={id}
            enableReinitialize={true}
            initialValues={humps.camelizeKeys(receiptItem)}
            onSubmit={saveAttachment}>
      <Form>
        <AutoSave debounceMs={1000} />
        <h4 className='name'><a href={editUrl}>Чек №{id}</a></h4>

        {meta.error && <div className='custom_error'>{meta.error}, <a href={editUrl}>обработать вручную</a></div>}
        
        {!!purchases.length ? renderFields() : (
          <div className='not_recognized'>Чек не распознался, <a href={editUrl}>обработать вручную</a></div>
        )}
      </Form>
    </Formik>
  );

  return renderContent();
};

export default ReceiptsShortForm;
