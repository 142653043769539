import IMask from 'imask'

const phoneMask = {
  mask: '+{7} (000) 000 00 00',
  autoUnmask: true,
  lazy: false,
}

const dateMask = {
  mask: Date,
  lazy: false,
}

$(function() {
  $('input.phone_mask').each((_, input) => { IMask(input, phoneMask) })
  $('input.date-time-picker-input').each((_, input) => { IMask(input, dateMask) })
})
