import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MaskedInput from "react-text-mask";
import humps from "humps";
import * as Yup from "yup";

import { identifySales } from "./api";

const SalesIdForm = ({ idMethod }) => {
  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    identifySales(values)
      .then(
        (response) => window.location.replace(response.data.redirectTo),
        (error) => console.log(error),
      )
      .finally(() => setSubmitting(false));
  };

  const SNILS_REGEXP = /\d{3}-\d{3}-\d{3} \d{2}/;
  const SNILS_MASK = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/];
  const PHONE_REGEXP = /\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/;
  const PHONE_MASK = ['+', '7', ' ', '(',  /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

  const validationSchemaSnils = Yup.object({
    snils: Yup.string().required("Обязателен для заполнения").matches(SNILS_REGEXP, {message: "Неверный формат"}),
  });

  const validationSchemaPhone = Yup.object({
    phone: Yup.string().required("Обязателен для заполнения").matches(PHONE_REGEXP, {message: "Неверный формат"}),
  });

  const validationSchemaEmail = Yup.object({
    email: Yup.string().required("Обязателен для заполнения").email(),
  });


  return (
    <div className="sales_id_form">
      {(() => {
        if (idMethod == "snils") {
          return (
          <div>
            <h1>Укажите свой СНИЛС</h1>
            <Formik initialValues={{snils: ""}} validationSchema={validationSchemaSnils} onSubmit={onSubmit} >
              <Form>
                <Field name="snils" type="text" render={({field}) => {
                  return <MaskedInput {...field} id={name} placeholder="999-999-999 99" mask={SNILS_MASK} />
                }} />
                <div className="common-error">
                  <ErrorMessage name="snils" />
                </div>
                <button type="submit">Применить</button>
              </Form>
            </Formik>
          </div>
          )
        } else if (idMethod == "email") {
          return (
          <div>
            <h1>Укажите свой email</h1>
            <Formik initialValues={{email: ""}} validationSchema={validationSchemaEmail} onSubmit={onSubmit} >
              <Form>
                <Field name="email" type="email" id={name} placeholder="example@mail.ru" />
                <div className="common-error">
                  <ErrorMessage name="email" />
                </div>
                <button type="submit">Применить</button>
              </Form>
            </Formik>
          </div>
          )
        } else {
          return (
          <div>
            <h1>Укажите свой номер телефона</h1>
            <Formik initialValues={{phone: ""}} validationSchema={validationSchemaPhone} onSubmit={onSubmit} >
              <Form>
                <Field name="phone" type="text" render={({field}) => {
                  return <MaskedInput {...field} id={name} placeholder="+7 (999) 999-99-99" mask={PHONE_MASK} />
                }} />
                <div className="common-error">
                  <ErrorMessage name="phone" />
                </div>
                <button type="submit">Применить</button>
              </Form>
            </Formik>
          </div>
          )
        }
      })()}
    </div>
  );
};

export default SalesIdForm;
