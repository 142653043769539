import React from 'react';

import { Formik, Form } from 'formik';
import humps from 'humps';

import AADatepicker from 'ActiveAdmin/AADatepicker';
import AACheckbox from 'ActiveAdmin/AACheckbox';
import AATextInput from 'ActiveAdmin/AATextInput';

import AutoSave from 'AttachmentForm/AutoSave';

// Вывод краткой формы обработки назначения
const AppointmentShortForm = ({ appointmentItem, saveAttachment }) => {
  const { id, meta } = appointmentItem;

  const renderContent = () => (
    <Formik key={id}
            initialValues={humps.camelizeKeys(appointmentItem)}
            onSubmit={saveAttachment}>
      <Form>
        <AutoSave debounceMs={1000} />
        <h4>Назначение №{id}</h4>
        <fieldset className='inputs appointments'>
          <ol>
            <AATextInput label='Город ЛПУ' name='clinicCity' />

            <AADatepicker label='Дата выписки рецепта' name='prescriptionDate' className='text_input' />
          </ol>

          <ol className='checkboxes_container'>
            <AACheckbox name='foreignDocument' label='Куплено/выписано за пределами России' />
            <AACheckbox name='fakeImage' label='Подозрение на мошенничество' />
            <AACheckbox name="sharpImage" label="Фото разборчивое" />
          </ol>

          <ol className='checkboxes_container'>
            <AACheckbox name='stamped' label='Печать' />
            <AACheckbox name='signed' label='Подпись' />
            <AACheckbox name='matchesTicketFullName'
                        label='ФИО клиента совпадает с тем, что в заявке'
                        hint={meta?.ticket_full_name && `В заявке: ${meta?.ticket_full_name}`} />
          </ol>
        </fieldset>
      </Form>
    </Formik>
  );

  return renderContent();
};

export default AppointmentShortForm;
