import React from "react";
import { Field, useFormikContext } from "formik";

import MedicinesInput from "./MedicinesInput";
import AAHasMany from "../ActiveAdmin/AAHasMany";

const PrescriptionMedicinesSubform = () => {
  const {
    values: { prescriptionMedicines },
  } = useFormikContext();

  return (
    <AAHasMany
      name="prescriptionMedicines"
      label="Назначенные препараты"
      items={prescriptionMedicines}
      newItem={{ medicineId: "", medicineName: "", medicineType: "" }}
      render={(_, index) => (
        <React.Fragment>
          <Field type="hidden" name="`prescriptionMedicines.${index}.id`" />
          <MedicinesInput
            label={`${index + 1}. ЛС/ДВ`}
            name={`prescriptionMedicines.${index}`}
            withActiveIngredients={true}
            createIfNotExists={true}
          />
        </React.Fragment>
      )}
    />
  );
};

export default PrescriptionMedicinesSubform;
