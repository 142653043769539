import React from 'react';

import { Formik, Form } from 'formik';
import humps from 'humps';

import AATextInput from 'ActiveAdmin/AATextInput';

// Вывод общего расчета кешбека и сумм по заявке
const TicketTotalLosses = ({ ticketCashback }) => {
  const renderContent = () => (
    <Formik key='total_ticket_losses'
            enableReinitialize={true}
            initialValues={humps.camelizeKeys(ticketCashback)}>
      <Form>
        <fieldset className='inputs'>
          <ol className='purchase_item column_names receipt_total'>
            <li><p>Итого по убытку:</p></li>
            <AATextInput name='quantity' wrapperClassName='number' disabled />
            <AATextInput name='amountPerUnit' wrapperClassName='number' disabled />
            <AATextInput name='amount' wrapperClassName='number' disabled />
            <AATextInput name='cashback' wrapperClassName='number' disabled />
          </ol>
        </fieldset>
      </Form>
    </Formik>
  );

  return renderContent();
};

export default TicketTotalLosses;
