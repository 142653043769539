import '../stylesheets/active_admin'

import '@activeadmin/activeadmin'
import 'activeadmin_addons'

import '../src/masks'
import '../src/confirms'

const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
