import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

const AADatepicker = ({ label, hint, dateFormat = "dd.MM.yyyy", ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  return (
    <li>
      <label htmlFor={props.id || props.name} className="custom_label">{label}</label>

      <DatePicker
        {...props}
        dateFormat={dateFormat}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(value) => setFieldValue(field.name, value)}
      />

      {meta.error ? <p className="custom_error">{meta.error}</p> : null}
      {hint ? <p className="custom_hint">{hint}</p> : null}
    </li>
  );
};

export default AADatepicker;
