import React from "react";
import { useFormikContext } from "formik";

import AACheckbox from "../ActiveAdmin/AACheckbox";
import AASelectInput from "../ActiveAdmin/AASelectInput";
import AATextInput from "../ActiveAdmin/AATextInput";
import AADatepicker from "../ActiveAdmin/AADatepicker";
import PrescriptionMedicinesSubform from "./PrescriptionMedicinesSubform";
import {
  prescriptionBlankTypeOptions,
  prescriptionTypeOptions,
} from "../selectOptions";

const PrescriptionSubform = () => {
  const {
    values: { matchesTicketFullName, meta },
  } = useFormikContext();

  return (
    <React.Fragment>
      <fieldset className="inputs">
        <ol>
          <AASelectInput
            label="Вид документа"
            name="prescriptionType"
            options={prescriptionTypeOptions}
          />
          <AASelectInput
            label="Машинописное"
            name="typewritten"
            boolean={true}
          />
          <AASelectInput
            label="Форма бланка"
            name="prescriptionBlankType"
            options={prescriptionBlankTypeOptions}
          />
          <AATextInput label="ИНН ЛПУ" name="treatmentFacilityInn" />
          <AATextInput label="Название ЛПУ" name="treatmentFacilityName" />
          <AATextInput label="Город ЛПУ" name="clinicCity" />
          <AADatepicker label="Дата выписки рецепта" name="prescriptionDate" />
          <AATextInput label="ФИО врача" name="doctorFullName" />
          <AACheckbox name="stamped" label="Печать" />
          <AACheckbox name="signed" label="Подпись" />
          <AACheckbox
            name="matchesTicketFullName"
            label="ФИО клиента совпадает с тем, что в заявке"
            hint={`В заявке: ${meta.ticketFullName}`}
          />
          {matchesTicketFullName ? null : (
            <AATextInput label="ФИО клиента" name="clientFullName" />
          )}
          <AATextInput label="Диагноз" name="diagnosis" />
          <AATextInput label="Код МКБ-10" name="icd10Code" />
        </ol>
      </fieldset>
      <PrescriptionMedicinesSubform />
    </React.Fragment>
  );
};

export default PrescriptionSubform;
