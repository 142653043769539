// Получение только id региона выбранного населенного пункта с регионом
export const getRegionId = ({ pharmacyRegion }) => {
  const { id, regionId } = pharmacyRegion;

  return regionId || id;
}

// Обработка и вывод ошибок из запроса к определенному полю в форме
export const handleFormError = (error, setFieldError) => {
  const errors = error.response.data;
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      setFieldError(key, errors[key]);
    }
  }
};

// Формирование данных для запроса,
// исключая пустые поля
export const buildAttachmentData = (input) => {
  const result = Object.assign({}, input);

  const isNotEmpty = (p) => p.medicineId != "";

  result.purchases = result.purchases.filter(isNotEmpty);
  result.prescriptionMedicines = result.prescriptionMedicines.filter(isNotEmpty);

  if (input.pharmacyRegion) result.pharmacyRegionId = getRegionId(input);

  return result;
}
